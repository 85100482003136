.app {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
    border-bottom: 10px solid #1C344B;
}


.app td{
    
    vertical-align: middle;
    justify-content: center;
    border-bottom: 10px solid #1C344B;
    min-height: 10pt;
    height: 1.5rem;
    font-size:  1.1rem;
    border-bottom-width: 0px;
   
    
}

.app  th {
    padding: 3px 2px;
    

  }
 
.app  tbody td {
    font-size: 13px;
  
    border-bottom-width: 0px;
    
  }

  .app  thead {
    background: #1C344B;
    color: #FFFFFF;
    border-bottom: 0px solid #444444;
  }

.app  thead th {
    font-size: 15px;
    font-weight: bold;
    color: #1C344B; 
    border-left: 2px solid #D0E4F5;
    border-bottom: 2px solid #1C344B;
  }

  .app  thead th:first-child {
    border-left: none;
  }

.app .matchCell{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 30pt;
    max-height: 8pt;
    height: .1rem;
    font-size:  .7rem;
}
.app .matchNameCell{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 100pt;
    max-height: 8pt;
    height: .1rem;
    font-size:  .7rem;
}


.app .rAction{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 10px;
    max-width: 110pt;
    min-height: 10pt;
    height: 1rem;
    font-size:  .7rem;
}

.app .rActionScore{
    
    vertical-align: middle;
    justify-content: center;
    max-width: 110pt;
    min-height: 10pt;
    height: 1rem;
    font-size:  .7rem;
}
.app .tdDelete{
    
    
    max-width:40px;

   
    
}
.app1 .tdAction{
    
    vertical-align: middle;
    justify-content: left;
    min-width:0px;
    height: 1rem;
    font-size:  10rem;
   
    
}

.ScoreBoard {
    
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    justify-content: center;
    background-color: #accce3;
    
    width: 100%;
    padding: 0px;
    margin-top: 0rem;
    border-bottom-color: #1C344B;
    
    border-spacing: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    
   

    
}

.ScoreBoard td{
    
    vertical-align: middle;
    justify-content: center;
  
    min-height: 10pt;
    min-width:0px;
    height: 1.5rem;
    font-size:  1rem;
}


.ScoreBoard .tdScore{
    
    vertical-align: middle;
    justify-content: center;
    min-width:0px;
    border-bottom-width: 0px;
    font-size:  3rem;
   
    
}

.ScoreBoard .team{
    
    vertical-align: middle;
    justify-content: center;
    min-width:120px;
    min-height: 140px;
    height: 1rem;
    font-size:  1.3rem;
    outline: 0px solid gray;
    border: 1px solid #1C344B;
   
    
}

.ScoreBoard .players{
    
    vertical-align: middle;
    justify-content: center;
    min-width:120px;
    min-height: 130px;
    height: 1rem;
    font-size:  1rem;
    outline: 0px solid gray;
   
    
}

.ScoreBoard .plusminus {
    justify-content: center;
    background-color: #accce3;
    width: 1rem;
    vertical-align: middle;
    margin-left: 0rem;
    margin-right: 0rem; 
    margin-bottom: 0rem;
    margin-top: 0rem;
    border-radius: 30%;
    border: none;
    margin:  0 rem;
    font-size:  1em;
    padding: 0;
    border-spacing: 0;
    padding-top: 0px;
    padding-bottom: 0px;
  
}
.optionsTable{
    
    display: flex;
    flex-direction: row;
    background-color:#accce3;
    justify-content: center;
    min-width:300px;
    width: 100%;
    padding: 0px;
    border-bottom-width: 300px;
  
    border-spacing: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size:  1em;
    outline: 0px solid gray;

}
.optionsTable .matchCell{
    font-size:  .8rem;
}


.optionsLabel{
    font-size:  .7em;   
}
.optionsTable td{
    
    vertical-align: middle;
    justify-content: center;
    border-color:  rgb(135, 196, 216);
    border-bottom-width: 0px;
    min-height: 10pt;
    height: 1.5rem;
    font-size:  1rem;
}

.serveTable{
    
    display: flex;
    flex-direction: row;
    background-color: #accce3;
    justify-content: center;
    min-width:300px;
    width: 100%;
    padding: 0px;
    border-bottom-width: 0px;
    border-width: 0px;
    border: 0px;
    border-spacing: 0;
    margin-top: 0rem;
    padding-top: 0px;
    padding-bottom: 0px;
    outline: 0px solid gray;
}
.serveTable .Servebutton {
    justify-content: center;
    background-color: rgb(231, 243, 252);
    width: 16rem;
    height: 4rem;
    margin-left: 0rem;
    margin-right: 0rem; 
    margin-bottom: 0rem;
    margin-top: 0rem;
    border-radius: 50%;
    border: none;
    margin:  0rem;
    font-size: 2rem;
    color: black;
    cursor: pointer;
}

.match {
    
    
   
    
    background-color: #accce3;
   
    
    outline: 0px solid gray;
}

.matchInput{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 260pt;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}
.datePicker{
    
        vertical-align: middle;
        justify-content: center;
        margin-left: 0px;
        padding-left: 0px;
        min-width: 80pt; 
        max-width: 110pt;    
        
    
    padding: 0;
    border-spacing: 0;
  
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}

.match .Servebutton {
    justify-content: center;
    background-color: rgb(231, 243, 252);
    
    width: 6rem;
    height: 3rem;
    line-height: 3rem;
    margin-left: 0rem;
    margin-right: 0rem; 
    margin-bottom: 1rem;
    margin-top: 0rem;
    border-radius: 20%;
    border: none;
    margin:  1rem;
    font-size: 1rem;
    color: black;
    cursor: pointer;
}


.myMatch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0rem;
    padding: 0;
    border:none ;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}


.match .matchShare{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 30pt;
    max-height: 3pt;
    
    font-size:  .5rem;
    padding: 0;
    border-spacing: 5;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: left;
    outline: 0px solid gray;
}

.myMatch .matchCell{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 30pt;
    max-height: 3pt;
    
    font-size:  .7rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}

.myMatch .teamCell{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 30pt;
    max-height: 3pt;
    
    font-size:  3rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: right;
    outline: 0px solid gray;
}

.myMatch .addButton{
    
    vertical-align: middle;
    justify-content: left;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 30pt;
    
    
    font-size: 3rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: left;
    outline: 0px solid gray;
}

.myMatch .matchCellMedium{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 50pt;
    max-height: 3pt;
    
    font-size:  .7rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}

.myMatch .matchCellWide{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 70pt;
    max-height: 3pt;
    
    font-size:  .7rem;
    padding: 0;
    border-spacing: 0;
   background-color: rgb#accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}
.myMatch .matchNameCell{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 120pt;
    max-height: 3pt;
   
    font-size:  .7rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}
.myMatch td{
    
   
    border-bottom-width: 0px;
   
    
}

.myGame {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 1px solid rgb(29, 59, 231);
}




.myGame .matchCell{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 30pt;
    max-height: 3pt;
    
    font-size:  1rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}



.myGame  .matchCellMedium{
    
    vertical-align: middle;
    justify-content: center;
    vertical-align: top;
    margin-top: 5px;
    padding-left: 0px;
    min-width: 50pt;
    max-height: 25pt;
    
    font-size:  1rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}

.myGame .matchCellWide{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 70pt;
    max-height: pt;
    
    font-size:  .7rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}
.myGame  .matchNameCell{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 120pt;
    max-height: 3pt;
   
    font-size:  .7rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}
.myGame  td{
    
   
    border-bottom-width: 0px;
   
    
}
.myGame .Scorebutton {
    justify-content: center;
    background-color: rgb(231, 243, 252);
    
    width: 6rem;
    height: 30px;
    vertical-align: middle;
    line-height: 1rem;
    margin-left: 0rem;
    margin-right: 0rem; 
    margin-bottom: 0rem;
    margin-top: rem;
    border-radius: 20%;
    border: none;
    margin:  1rem;
    font-size: 1rem;
    color: black;
    cursor: pointer;
}

.singUP {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 0rem;
    padding: 10;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid rgb(29, 59, 231);
}

.singUP .matchCellMedium{
    
    vertical-align: middle;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 50pt;
    max-height: 3pt;
    
    font-size:  .7rem;
    padding: 0;
    border-spacing: 0;
   background-color: #accce3;
    padding-bottom: 0px;
    text-align: center;
    outline: 0px solid gray;
}
.singUP  td{
    
   
    border-bottom-width: 0px;
}

.myAppBar {
    
    background: #1C6EA4;
    color: #FFFFFF; 
    font-size:  .7rem;
}
.myAppBar .TableCell{
   
    
}
.myAppBar  td{
    
    
       
}
.myAppBar  tr{
    
    
}
.HomeButton {
    
    color: white;
  
}

.HomeIcon {
    padding: 0;
    
    color: white;
    
}

table.myMatchesTop {
    border: 1px solid #1C344B;
    width: 100%;
    background-color: #accce3;
    text-align: center;
    border-collapse: collapse;
    padding-bottom: 0px;
    padding: 0;
    
    
  }
table.myMatchesTop td {
   
    text-align: center;
    border-collapse: collapse;
    border-bottom-width: 0px;
    padding-bottom: 0px;
    padding: 0;
}

table.myMatches {
    border: 1px solid #1C344B;
    width: 100%;
    background-color: #accce3;
    text-align: center;
    border-collapse: collapse;
    padding-bottom: 0px;
    padding: 0;
    
    
  }
  table.myMatches td {
    padding: 3px  10px;
    border-bottom-width: 0px;
    padding-bottom: 0px;
    text-align: center;
    

  }

table.myMatches th {
    padding: 3px  10px;
    border-bottom-width: 0px;
    padding-bottom: 0px;
    

  }
  table.myMatches thead td {
    font-size: 15px;
  
    border-bottom-width: 0px;
    
  }
  table.myMatches tbody td {
    font-size: 15px;
  
    border-bottom-width: 0px;
    
  }
  table.myMatches thead {
    background: #1C344B;
    color: #FFFFFF;
    border-bottom: 0px solid #444444;
  }


  
  table.myMatches tfoot td {
    font-size: 14px;
  }
  table.myMatches tfoot .links {
    text-align: right;
  }
  table.myMatches tfoot .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
  }
  /* unvisited link */
  table.myMatches a:link {
    color: #FFFFFF;
  }
  
  /* visited link */
  table.myMatches a:visited {
    color: #FFFFFF;
  }
  
  /* mouse over link */
  table.myMatches a:hover {
    color: #FFFFFF;
  }
  
  /* selected link */
  table.myMatches a:active {
    color: #FFFFFF;


  }

  table.myMatchesN {
    border: 11  px solid #465360;
    width: 100%;
    background-color: #accce3;
    text-align: center;
    border-collapse: collapse;
    padding-bottom: 0px;
    padding: 0;
    
    
  }
  table.myMatchesN td {
    padding: 3px 3px;
    font-size: 15px;
   

  }
   table.myMatchesN th {
    padding: 3px 2px;
    font-size: 15px;
    font-weight: normal;
    border-left: 1px solid #879fae;

  }
  table.myMatchesN tbody td {
    font-size: 13px;
    color: #FFFFFF;
    border-bottom-width: 0px;
    
    
    
  }
  table.myMatchesN thead {
    background: #1C344B;
    color: #FFFFFF;
    border-bottom: 0px solid #444444;
    
    
  }
 
  @import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

  * {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  
  main {
   
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Pacifico", cursive;
 
    text-align: center;
  }
  
  h1 {
    color: white;
  }
  
  #snow-container {  
    height: 100%;
    background-color: #accce3;
    overflow: hidden;
    position: absolute;
    
    transition: opacity 500ms;
    width: 100%;
  }
  
  .snow {
    animation: fall ease-in infinite, sway ease-in-out infinite;
    color: rgb(200, 227, 237);
    position: absolute;
  }
  
  footer {
    background-color: #ffdfb9;
    bottom: 0;
    font-family: sans-serif;
    padding: 1rem;
    text-align: center;
    width: 100%;
  }
  
  footer a {
    color: inherit;
    text-decoration: none;
  }
  
  footer .heart {
    color: #dc143c;
  }
  
  @keyframes fall {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      top: 100vh;
      opacity: 1;
    }
  }
  
  @keyframes sway {
    0% {
      margin-left: 0;
    }
    25% {
      margin-left: 50px;
    }
    50% {
      margin-left: -50px;
    }
    75% {
        margin-left: 50px;
      }
      100% {
        margin-left: 0;
      }
    }